<template>
  <div>
    <vx-tooltip
      class="cursor-pointer navbar-fuzzy-search ml-4 mt-2"
      :text="dbData.s"
      position="right"
    >
      <feather-icon
        @click.stop="dbSettings = !dbSettings"
        :class="`text-${dbData.m}`"
        icon="DatabaseIcon"
      />
      <feather-icon
        @click.stop="dbSettings = !dbSettings"
        :class="`text-${dbData.m}`"
        :icon="`${type(dbData.s)}Icon`"
      />
    </vx-tooltip>
    <span class="ml-2 mt-1" v-if="dbData.a">Database Error</span>

    <vs-popup title="Database Config" :active.sync="dbSettings">
      <div class="vx-row">
        <div class="vx-col w-full ml-4">
          <span class="text-sm"
            >We keep secure cloud periodic backups of your data encrypted at our
            database servers</span
          >
        </div>
        <div class="vx-row ml-6 mr-6 mt-4">
          <div class="vx-col">
            <feather-icon
              @click.stop="dbSettings = !dbSettings"
              :class="`text-${dbData.m}`"
              icon="DatabaseIcon"
            />
            <feather-icon
              @click.stop="dbSettings = !dbSettings"
              :class="`text-${dbData.m}`"
              :icon="`${type(dbData.s)}Icon`"
            />
          </div>
          <div class="vx-col text-sm">{{ dbData.s }}</div>
        </div>
        <div
          v-if="allow_backups && this.$acl.check('admin')"
          class="vx-row w-full ml-4 mr-4"
        >
          <!-- <vs-divider>Database</vs-divider> -->
          <div class="vx-col w-full mt-2">
            <span class="text-sm">
              To create a new database and migrate, please contact our Support
              Team.</span
            >
          </div>
          <div v-if="!create_db" class="vx-col w-full mt-2">
            <vs-input
              label="Database ID"
              v-model="link_db"
              class="w-full"
              name="remote_db"
              v-validate="'required'"
            />
            <span class="text-sm">
              Enter external installation id to link this database with the
              external database.</span
            >
            <br />
            <span class="text-danger">{{ errors.first('remote_db') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full mt-5">
            <vs-select
              v-model="protocol"
              class="w-full select-large"
              label="Protocol"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in protocolOptions"
                class="w-full"
              />
            </vs-select>
          </div>
          <div class="vx-col md:w-1/3 w-full mt-5">
            <vs-input
              v-on:keyup.alt.67="overide"
              label="Remote DB Url"
              v-model="remote_url"
              class="w-full"
              name="remote_url"
              v-validate="{ required: true }"
            />
            <span class="text-danger">{{ errors.first('remote_url') }}</span>
          </div>
          <div class="vx-col md:w-1/3 w-full mt-5">
            <vs-input
              label="Remote DB Port"
              v-model="remote_port"
              class="w-full"
              name="remote_port"
              v-validate="'numeric'"
            />
            <span class="text-danger">{{ errors.first('remote_port') }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-5">
            <vs-input
              label="Remote DB Username"
              v-model="remote_username"
              class="w-full"
              name="remote_username"
              v-validate="'alpha'"
            />
            <span class="text-danger">{{
              errors.first('remote_username')
            }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-5">
            <vs-input
              type="password"
              label="Remote DB Password"
              v-model="remote_password"
              class="w-full"
              name="remote_password"
            />
            <span class="text-danger">{{
              errors.first('remote_password')
            }}</span>
          </div>
        </div>

        <div
          v-if="dbDataValid && this.$acl.check('admin')"
          @click="updateDB"
          class="w-full ml-6 mr-6 mt-6 btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-white bg-primary"
        >
          <feather-icon icon="CheckIcon" svgClasses="h-4 w-4" />
          <span class="ml-2 text-base text-white">Update</span>
        </div>
        <span v-if="timeout < 60" class="mt-2 vx-col ml-6 mr-6 text-sm">
          This window will close automatically after {{ timeout }} second{{
            timeout == 1 ? '' : ''
          }}s.</span
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import idb from '@/db/idb';
import p from '@/http/pouch/index.js';
import PouchDb from 'pouchdb';

export default {
  name: 'database',
  data() {
    return {
      dbSettings: false,

      timeout: 120,
      timer: null,

      allow_backups: true,
      create_db: false,
      link_db: '',
      dbOptions: [
        {
          label: 'Create new database',
          value: true,
        },
        {
          label: 'Link existing database',
          value: false,
        },
      ],
      protocol: 'https',
      protocolOptions: ['https', 'http'],
      remote_url: 'db.vue.co.ke',
      remote_port: 6984,
      remote_username: null,
      remote_password: null,
      override_credentials: false,
      db: null,
    };
  },
  watch: {
    protocol(v) {
      switch (this.protocol) {
        case 'https':
          this.remote_port = 6984;
          break;

        default:
          this.remote_port = 5984;
          break;
      }
    },
    dbSettings(v) {
      switch (v) {
        case true:
          this.startTimer();
          setTimeout(() => {
            this.dbSettings = false;
          }, 60 * 1000);
          break;
        case false:
          clearInterval(this.timer);
          this.timeout = 60;
          break;
      }
    },
  },
  computed: {
    dbDataValid() {
      return (
        !this.errors.any() &&
        this.link_db &&
        this.remote_url &&
        this.remote_port &&
        this.remote_password
      );
    },
    dbData() {
      return this.$store.state.db;
    },
  },
  methods: {
    updateDB() {
      this.checkDB();
    },
    getDB() {
      return `${this.protocol}://${this.remote_username}:${this.remote_password}@${this.remote_url}:${this.remote_port}/${this.link_db}`;
    },
    checkDB() {
      let dbString = this.getDB();
      const db = new PouchDb(dbString, { skip_setup: true });
      db.info()
        .then(async (d) => {
          // The database exists.
          if (d.error) {
            this.$vs.notify({
              color: 'danger',
              title: 'Error',
              text: d.reason,
            });
          } else {
            await idb.saveRecord({
              type: 'database',
              db: dbString,
              allow_backups: this.allow_backups,
              create_db: this.create_db,
              link_db: this.link_db,
              remote_url: this.remote_url,
              remote_username: this.remote_username,
              remote_password: this.remote_password,
              protocol: this.protocol,
              remote_port: this.remote_port,
            });
            p.init(this.$pouch);
            this.$vs.notify({
              color: 'success',
              title: 'DB Connected',
              text: 'DB found and connected. Sync will run in the background.',
            });
            this.dbSettings = false;
          }
        })
        .catch((e) => {
          this.$vs.notify({
            color: 'danger',
            title: 'Error',
            text: 'Database found and it was not created.',
          });
        });
    },
    async getDefaultData() {
      await this.fill(
        [
          'db',
          'link_db',
          'remote_url',
          'remote_username',
          'allow_backups',
          'protocol',
          'remote_port',
        ],
        'database',
      );
      if (this.db !== null) {
        var dbArray = this.db.split('/');
        this.link_db = dbArray[3];
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.timeout--;
      }, 1000);
    },
    type(str) {
      var a = str.split(' ');
      var v = false;
      a.map((t) => {
        switch (t) {
          case 'download':
            v = 'Download';
            break;
          case 'upload':
            v = 'Upload';
            break;
        }
      });
      return v;
    },
    async getRecord(type) {
      return await idb.getRecord(type);
    },
    async fill(a, t) {
      let d = (await this.getRecord(t)) || null;
      if (d) {
        for (let i = 0; i < a.length; i++) {
          const e = a[i];
          if (this.hasOwnProperty(e) && d.hasOwnProperty(e)) {
            this[`${e}`] = d[e];
          }
        }
        return d;
      }
      return false;
    },
  },
  created() {
    this.getDefaultData();
    this.remote_password = '';
  },
};
</script>
