<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
========================================================================================== -->

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0">
      <vs-navbar
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
        class="navbar-custom navbar-skelton"
      >
        <bookmarks v-if="windowWidth >= 992" :navbar-color="navbarColor" />

        <router-link
          tag="div"
          to="/"
          class="vx-logo cursor-pointer mx-auto flex items-center"
        >
          <logo
            v-if="!$root.logo"
            class="w-12 mr-6 fill-current text-primary"
          />

          <img
            v-if="$root.logo"
            class="w-12 mr-6 fill-current text-primary"
            :src="$root.logo"
            alt="logo"
          />

          <!-- <vx-tooltip position="bottom"> -->
          <span
            :class="`vx-logo-text ${isThemedark === 'dark' ? '' : 'text-primary'}`"
            >{{
              this.$root.businessData && this.$root.businessData.data
                ? this.$root.businessData.data.business_name
                : 'Express POS'
            }}</span
          >
          <!-- </vx-tooltip> -->
        </router-link>

        <database />
        <setting />

        <!-- <i18n /> -->

        <feather-icon
          :icon="isThemedark === 'dark' ? 'SunIcon' : 'MoonIcon'"
          svg-classes="cursor-pointer navbar-fuzzy-search ml-4"
          @click="toggleTheme"
        />

        <search-bar />

        <notification-drop-down class="ml-4" />

        <profile-drop-down />

        <div v-if="electronRemote" class="vx-row ml-4 mr-4">
          <vx-tooltip position="bottom" text="Minimize">
            <feather-icon
              :svg-classes="['h-5 w-5 stroke-current', textColor]"
              class="ml-2 mt-2 mr-1 hover:text-primary"
              icon="MinusIcon"
              @click="currentWindow().minimize()"
            />
          </vx-tooltip>
          <vx-tooltip
            v-if="currentWindow().isMaximizable()"
            position="bottom"
            text="Maximize"
          >
            <feather-icon
              :svg-classes="['h-5 w-5 stroke-current', textColor]"
              class="ml-2 mt-2 mr-1 hover:text-primary"
              icon="Maximize2Icon"
              @click="currentWindow().maximize()"
            />
          </vx-tooltip>
          <vx-tooltip position="bottom" text="Close">
            <feather-icon
              :svg-classes="['h-5 w-5 stroke-current', textColor]"
              class="ml-2 mt-2 mr-1 hover:text-danger"
              icon="XIcon"
              @click="$root.closeApp(currentWindow())"
            />
          </vx-tooltip>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import Logo from '../Logo.vue';
import Bookmarks from './components/Bookmarks.vue';
import Database from './components/Database.vue';
import I18n from './components/I18n.vue';
import NotificationDropDown from './components/NotificationDropDown.vue';
import ProfileDropDown from './components/ProfileDropDown.vue';
import SearchBar from './components/SearchBar.vue';
import Setting from './components/Setting.vue';

export default {
  name: 'TheNavbarHorizontal',
  components: {
    Logo,
    Bookmarks,
    I18n,
    SearchBar,
    NotificationDropDown,
    ProfileDropDown,
    Database,
    Setting,
  },
  props: {
    // logo: { type: String },
    navbarType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      electronRemote: null,
    };
  },
  pouch: {
    logoData() {
      return {
        database: 'express',
        selector: { _id: 'logo' },
        first: true,
      };
    },
  },
  computed: {
    textColor() {
      return {
        'text-white':
          this.$store.state.mainLayoutType === 'vertical' &&
          this.navbarColor !=
            (this.$store.state.theme === 'dark' ? '#10163a' : '#fff'),
      };
    },
    navbarColor() {
      let color = '#fff';
      if (this.navbarType === 'sticky') color = '#f7f7f7';
      else if (this.navbarType === 'static') {
        if (this.scrollY < 50) {
          color = '#f7f7f7';
        }
      }

      this.isThemedark === 'dark'
        ? color === '#fff'
          ? (color = '#10163a')
          : (color = '#262c49')
        : null;

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === 'static'
        ? { transition: 'all .25s ease-in-out' }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === 'static'
        ? null
        : 'd-theme-dark-light-bg shadow-none';
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    currentWindow() {
      if (this.electronRemote) {
        return this.electronRemote.getCurrentWindow();
      }
      return null;
    },
    toggleTheme() {
      this.$store.dispatch(
        'updateTheme',
        this.isThemedark === 'dark' ? 'light' : 'dark',
      );
    },
  },
  mounted() {
    if (window.require) {
      this.electronRemote = window.require('electron').remote;
    }
  },
};
</script>
