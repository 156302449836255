<template>
  <div class="the-navbar__user-meta flex items-center">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">
        {{ activeUserInfo.name }}
      </p>
      <small>{{ activeUserInfo.username }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <img
          key="onlineImg"
          :src="require('@/assets/images/portrait/small/user.jpeg')"
          alt="user-img"
          width="40"
          height="40"
          class="rounded-full shadow-md cursor-pointer block"
        />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <!-- <vs-divider class="m-1" /> -->
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="app = true"
          >
            <feather-icon icon="KeyIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">License</span>
          </li>
          <vs-popup
            class="holamundo"
            title="System Licensed"
            :active.sync="app"
          >
            <p>
              This installation of Express POS has been licensed to
              <strong>{{ business_name }}</strong>
            </p>
            <br />
            <span
              ><strong>Installation ID: </strong
              ><code>{{ installationID }}</code></span
            ><br />
            <span><strong>Date Licensed: </strong>{{ parseDate(start) }}</span
            ><br />
            <span><strong>License Expiry: </strong>{{ parseDate(expiry) }}</span
            ><br /><br />
            <p>Thank you for installing Express POS</p>
          </vs-popup>
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="about = true"
          >
            <feather-icon icon="InfoIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">About</span>
          </li>
          <vs-popup
            class="holamundo"
            title="About Express POS"
            :active.sync="about"
          >
            <p>
              We appreciate you for using our point of sale software. Express
              POS was designed and developed by: <br /><br />
              <strong>Cloud Wave Solutions</strong><br />
              support@cw.co.ke<br />+254 100 200 219
            </p>
            <br />
            <span
              >&copy; {{ new Date().getFullYear() }}
              <a href="#!" target="_blank" rel="nofollow"
                >Cloud Wave Solutions</a
              >, All rights Reserved</span
            >
          </vs-popup>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import idb from '@/db/idb';

export default {
  data() {
    return {
      app: false,
      about: false,
      business_name: '',
      installationID: '',
      start: Date.now(),
      expiry: Date.now(),
    };
  },
  computed: {
    activeUserInfo() {
      return this.$session.get('user');
    },
    isThemedark() {
      return this.$store.state.theme;
    },
  },
  methods: {
    logout() {
      this.$session.destroy();
      this.$vs.notify({
        title: 'Logout Successful',
        text: `Redirecting to login page`,
        iconPack: 'feather',
        icon: 'icon-check-circle',
        color: 'success',
      });
      this.$acl.change('public');
      this.$router.push('/login').catch(() => {});
    },
    parseDate(d) {
      return this.$moment(d).format('dddd, MMMM Do YYYY');
    },
    async getRecord(type) {
      return await idb.getRecord(type);
    },
    async fill(a, t) {
      let d = (await this.getRecord(t)) || null;
      if (d) {
        for (let i = 0; i < a.length; i++) {
          const e = a[i];
          if (this.hasOwnProperty(e) && d.hasOwnProperty(e)) {
            this[`${e}`] = d[e];
          }
        }
        return d;
      }
      return false;
    },
  },
  async created() {
    await this.fill(['installationID', 'start', 'expiry'], 'license');
    await this.fill(['business_name'], 'business');
  },
};
</script>
