<template>
  <div class="flex">
    <div
      v-show="showFullSearch"
      :class="{ flex: showFullSearch }"
      class="search-full-container w-full h-full absolute left-0 top-0 mr-4"
    >
      <vx-auto-suggest
        ref="navbarSearch"
        :auto-focus="showFullSearch"
        :data="navbarSearchAndPinList"
        search_key="title"
        background-overlay
        class="w-full"
        input-classses="w-full vs-input-no-border vs-input-no-shdow-focus"
        icon="SearchIcon"
        placeholder="Explore Express POS ..."
        @input="hnd_search_query_update"
        @selected="selected"
        @closeSearchbar="showFullSearch = false"
      >
        <template v-slot:group="{ group_name }">
          <p class="font-semibold text-primary">{{ group_name | title }}</p>
        </template>

        <!-- Pages Suggestion -->
        <template v-slot:pages="{ suggestion }">
          <div class="flex items-end leading-none py-1">
            <feather-icon
              :icon="suggestion.icon"
              svg-classes="h-5 w-5"
              class="mr-4"
            />
            <span class="mt-1">{{ suggestion.title }}</span>
          </div>
        </template>

        <!-- Files Suggestion
        <template v-slot:customers="{ suggestion }">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <div class="img-container w-8 mr-3 flex">
                <img
                  :src="require(`@/assets/images/file-icons/${suggestion.file_ext}.png`)"
                  :alt="suggestion.name"
                  class="responsive"
                />
              </div>
              <div class="leading-none mt-1">
                <p class="mb-1">{{ suggestion.name }}</p>
                <small>by {{ suggestion.from }}</small>
              </div>
            </div>
            <small>{{ suggestion.size }}</small>
          </div>
        </template>-->

        <!-- Contacts Suggestion -->
        <template v-slot:items="{ suggestion }">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <div class="leading-none mt-1">
                <p>
                  {{ suggestion.product_code }} - {{ suggestion.product_name }}
                </p>
                <small
                  >Availability
                  {{ formatProductStock(suggestion.stock) }}</small
                >
              </div>
            </div>
            <div class="flex flex-col">
              <small>[Retail] Ksh. {{ suggestion.retail_price }}</small>
              <small v-if="suggestion.wholesale_price"
                >[Wholesale] Ksh. {{ suggestion.wholesale_price }}</small
              >
            </div>
          </div>
        </template>

        <!-- Contacts Suggestion -->
        <template v-slot:customers="{ suggestion }">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <!-- <img :src="suggestion.img" :alt="suggestion.name" class="w-8 h-8 mr-3 rounded-full" /> -->
              <div class="leading-none mt-1">
                <p>{{ suggestion.name }}</p>
                <small>{{ suggestion.phone_number }}</small>
              </div>
            </div>
          </div>
        </template>

        <!-- No Items Slot -->
        <template v-slot:noResult="{ group_name }">
          <div class="flex items-center">
            <feather-icon icon="InfoIcon" svg-classes="h-5 w-5" class="mr-4" />
            <span>No results found in {{ group_name }}.</span>
          </div>
        </template>
      </vx-auto-suggest>

      <div class="absolute right-0 h-full z-50">
        <feather-icon
          icon="XIcon"
          class="px-4 cursor-pointer h-full close-search-icon"
          @click="showFullSearch = false"
        />
      </div>
    </div>
    <feather-icon
      icon="SearchIcon"
      class="cursor-pointer navbar-fuzzy-search ml-4"
      @click="showFullSearch = true"
    />
  </div>
</template>

<script>
import VxAutoSuggest from '@/components/vx-auto-suggest/VxAutoSuggest.vue';

export default {
  components: {
    VxAutoSuggest,
  },
  data() {
    return {
      // navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      showFullSearch: false,
    };
  },
  pouch: {
    productData() {
      return {
        database: 'express',
        selector: { type: 'product' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
    customerData() {
      return {
        database: 'express',
        selector: { type: 'customer' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
    branchData() {
      return {
        database: 'express',
        selector: { type: 'branch' },
        sort: [
          {
            branch_name: 'asc',
          },
        ],
      };
    },
  },

  computed: {
    navbarSearchAndPinList() {
      return {
        pages: {
          key: 'title',
          data: [
            // DASHBOARDS
            {
              title: 'Items',
              url: '/store/items',
              icon: 'ShoppingBagIcon',
              is_bookmarked: true,
            },
            {
              title: 'New Sale',
              url: '/store/sales/new',
              icon: 'ShoppingCartIcon',
              is_bookmarked: true,
            },
            {
              title: 'New Job',
              url: '/store/jobs/new',
              icon: 'BriefcaseIcon',
              is_bookmarked: true,
            },
            {
              title: 'Customers',
              url: '/store/customers',
              icon: 'UsersIcon',
              is_bookmarked: true,
            },
            {
              title: 'Employees',
              url: '/store/employees',
              icon: 'AnchorIcon',
              is_bookmarked: true,
            },
          ],
        },
        customers: {
          key: 'name',
          data: this.customerData,
        },
        items: {
          key: 'product_name',
          data: this.productData,
        },
        // branches: {
        //   key: "name",
        //   data: this.branchData,
        // },
        // contacts: {
        //   key: "name",
        //   data: [],
        // },
      };
    },
  },
  created() {},
  methods: {
    selected(item) {
      if ('customers' in item) {
        this.$router
          .push(`/store/customers/${item['customers']._id}`)
          .catch(() => {});
        return;
      } else if ('items' in item) {
        this.$router.push(`/store/items`).catch(() => {});
        return;
      }
      item.pages ? this.$router.push(item.pages.url).catch(() => {}) : null;
      this.showFullSearch = false;
    },
    hnd_search_query_update(query) {
      // Show overlay if any character is entered
      this.$store.commit('TOGGLE_CONTENT_OVERLAY', query ? true : false);
    },
    formatProductStock(stock) {
      const keys = Object.keys(stock);

      return keys
        .map((key) => {
          let branch = this.branchData.find((e) => e._id === key);
          branch = branch ? branch.branch_name : key;
          return `${branch}: ${stock[key]} `;
        })
        .join(',');
    },
  },
};
</script>
