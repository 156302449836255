/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Structure:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

export default [
  {
    url: '/dashboard',
    name: 'Dashboard',
    icon: 'HomeIcon',
    slug: '/dashboard',
  },
  {
    url: '/store/sales/new',
    name: 'New Sale',
    icon: 'ShoppingCartIcon',
    slug: '/store/sales/new',
  },
  {
    url: '/store/purchases/new',
    name: 'New PO',
    icon: 'CheckSquareIcon',
    slug: '/store/purchases/new',
  },
  {
    header: 'Store',
    items: [
      {
        url: '/store/customers',
        name: 'Customers',
        icon: 'UsersIcon',
        slug: '/store/customers',
      },
      {
        url: '/store/items',
        name: 'Items',
        icon: 'ShoppingBagIcon',
        slug: '/store/items',
      },

      {
        url: '/store/suppliers',
        name: 'Suppliers',
        icon: 'TruckIcon',
        slug: '/store/suppliers',
      },
      {
        url: '/store/branches',
        name: 'Branches',
        icon: 'GitBranchIcon',
        slug: '/store/branches',
      },
      {
        url: '/store/payment-options',
        name: 'Payment Options',
        icon: 'CreditCardIcon',
        slug: '/store/payment-options',
      },
    ],
  },
  {
    header: 'Operations',
    items: [
      {
        url: '/store/sales',
        name: 'Sales',
        icon: 'ShoppingCartIcon',
        slug: '/store/sales',
      },
      {
        url: '/store/purchases',
        name: 'Purchases',
        icon: 'CheckSquareIcon',
        slug: '/store/purchases',
      },
    ],
  },
  {
    header: 'HR',
    items: [
      {
        url: '/system/users',
        name: 'Users',
        icon: 'AnchorIcon',
        slug: '/system/users',
      },
    ],
  },
  {
    header: 'Reports',
    items: [
      {
        url: '/store/report',
        name: 'All Reports',
        icon: 'PieChartIcon',
        slug: '/store/report',
      },
    ],
  },
  // {
  //   header: "System",
  //   items: [{
  //     url: null,
  //     name: "Settings",
  //     icon: "SettingsIcon",
  //     submenu: [{
  //         url: "/settings/payment-methods",
  //         name: "Payment Methods",
  //         slug: "/settings/payment-methods",
  //       },
  //       {
  //         url: "/settings/product-categories",
  //         name: "Product Categories",
  //         slug: "/settings/product-categories",
  //       }
  //     ]
  //   }]
  // }
];
