const helper = {
  watch: {
    // async businessData(val) {
    //   console.log('val', val);
    //   if ('_attachments' in val) {
    //     if (val._attachments.logo) {
    //       this.logo = await this.getLogo(val._id).then((blob) =>
    //         URL.createObjectURL(blob),
    //       );
    //       return;
    //     }
    //   }
    //   this.logo = '';
    // },
  },
  created() {
    console.log('helper mixin ready');
  },
  pouch: {
    businessData() {
      return {
        database: 'express',
        selector: { _id: 'business' },
        first: true,
      };
    },
  },
  methods: {
    // getLogo(docId) {
    //   return this.$pouch.getAttachment(docId, 'logo');
    // },
  },
};

export default helper;
