<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
========================================================================================== -->

<template>
  <div class="relative">
    <div :class="classObj" class="vx-navbar-wrapper">
      <vs-navbar
        :color="navbarColorLocal"
        :class="textColor"
        class="vx-navbar navbar-custom navbar-skelton"
      >
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer p-2"
          icon="MenuIcon"
          @click.stop="showSidebar"
        />

        <bookmarks v-if="windowWidth >= 992" :navbar-color="navbarColor" />

        <vs-spacer />

        <database />
        <setting />

        <!-- <i18n /> -->

        <feather-icon
          :icon="isThemedark === 'dark' ? 'SunIcon' : 'MoonIcon'"
          svg-classes="cursor-pointer navbar-fuzzy-search ml-4"
          @click="toggleTheme"
        />

        <search-bar />

        <notification-drop-down class="ml-4" />

        <profile-drop-down />

        <div v-if="electronRemote" class="vx-row ml-4 mr-4">
          <vx-tooltip position="bottom" text="Minimize">
            <feather-icon
              :svg-classes="['h-5 w-5 stroke-current', textColor]"
              class="ml-2 mt-2 mr-1 hover:text-primary"
              icon="MinusIcon"
              @click="currentWindow().minimize()"
            />
          </vx-tooltip>
          <vx-tooltip
            v-if="currentWindow().isMaximizable()"
            position="bottom"
            text="Maximize"
          >
            <feather-icon
              :svg-classes="['h-5 w-5 stroke-current', textColor]"
              class="ml-2 mt-2 mr-1 hover:text-primary"
              icon="Maximize2Icon"
              @click="currentWindow().maximize()"
            />
          </vx-tooltip>
          <vx-tooltip position="bottom" text="Close">
            <feather-icon
              :svg-classes="['h-5 w-5 stroke-current', textColor]"
              class="ml-2 mt-2 mr-1 hover:text-danger"
              icon="XIcon"
              @click="$root.closeApp(currentWindow())"
            />
          </vx-tooltip>
        </div>

        <!-- <div v-if="$root.isNative()" class="vx-row ml-4 mr-4">
          <vx-tooltip position="bottom" text="Minimize">
            <feather-icon
              :svg-classes="['h-5 w-5 stroke-current', textColor]"
              class="ml-2 mt-2 mr-1 hover:text-primary"
              icon="MinusIcon"
              @click="$root.currentWindow.minimize()"
            />
            TAURI  $root.appWindow.minimize()
          </vx-tooltip>
          <vx-tooltip
            v-if="$root.currentWindow.isMaximizable()"
            position="bottom"
            text="Maximize"
          >
            <feather-icon
              :svg-classes="['h-5 w-5 stroke-current', textColor]"
              class="ml-2 mt-2 mr-1 hover:text-primary"
              icon="Maximize2Icon"
              @click="$root.currentWindow.maximize()"
            />
           TAURI @click="
                $root.appWindow.isMaximized()
                  ? $root.appWindow.maximize()
                  : $root.appWindow.unmaximize()
              "
          </vx-tooltip>
          <vx-tooltip position="bottom" text="Close">
            <feather-icon
              :svg-classes="['h-5 w-5 stroke-current', textColor]"
              class="ml-2 mt-2 hover:text-danger"
              icon="XIcon"
              @click="$root.closeApp"
            />
          </vx-tooltip>
        </div> -->
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import Bookmarks from './components/Bookmarks.vue';
import Database from './components/Database.vue';
import I18n from './components/I18n.vue';
import NotificationDropDown from './components/NotificationDropDown.vue';
import ProfileDropDown from './components/ProfileDropDown.vue';
import SearchBar from './components/SearchBar.vue';
import Setting from './components/Setting.vue';

export default {
  name: 'TheNavbarVertical',
  components: {
    Bookmarks,
    I18n,
    SearchBar,
    NotificationDropDown,
    ProfileDropDown,
    Database,
    Setting,
  },
  props: {
    navbarColor: {
      type: String,
      default: '#fff',
    },
  },
  data() {
    return {
      electronRemote: null,
    };
  },
  computed: {
    isThemedark() {
      return this.$store.state.theme;
    },
    textColor() {
      return {
        'text-white':
          this.$store.state.mainLayoutType === 'vertical' &&
          this.navbarColorLocal !=
            (this.$store.state.theme === 'dark' ? '#10163a' : '#fff'),
      };
    },

    navbarColorLocal() {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff'
        ? '#10163a'
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    // textColor() {
    //   return {
    //     "text-white":
    //       (this.navbarColor != "#10163a" &&
    //         this.$store.state.theme === "dark") ||
    //       (this.navbarColor != "#fff" && this.$store.state.theme !== "dark"),
    //   };
    // },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == 'default') return 'navbar-default';
      else if (this.verticalNavMenuWidth == 'reduced') return 'navbar-reduced';
      else if (this.verticalNavMenuWidth) return 'navbar-full';
    },
  },
  methods: {
    currentWindow() {
      if (this.electronRemote) {
        return this.electronRemote.getCurrentWindow();
      }
      return null;
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.timeout--;
      }, 1000);
    },
    type(str) {
      var a = str.split(' ');
      var v = false;
      a.map((t) => {
        switch (t) {
          case 'download':
            v = 'Download';
            break;
          case 'upload':
            v = 'Upload';
            break;
        }
      });
      return v;
    },
    toggleTheme() {
      this.$store.dispatch(
        'updateTheme',
        this.isThemedark === 'dark' ? 'light' : 'dark',
      );
    },
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    },
  },
  mounted() {
    if (window.require) {
      this.electronRemote = window.require('electron').remote;
    }
  },
};
</script>
